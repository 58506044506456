<template>
  <hb-basic-page
    :title="$tc('sites.title', 2)"
    :loading="loading && !subtleLoading"
    :fill-height="loading"
    :extension-height="60"
    :search-title="`${$t('sites.search')}...`"
    class="worksites-list"
    @handle-search="handleSearch"
  >
    <template v-slot:app-bar-extension>
      <v-btn class="mb-3 ml-n1" @click="createSite" color="primary" v-if="$vuetify.breakpoint.smAndUp && $isTenantAdmin">
        <v-icon left class="no-gradient">mdi-plus</v-icon>
        <span>{{ $t('sites.new_site.buttons.add_new_work_site') }}</span>
      </v-btn>
    </template>
    <v-col cols="12" class="gradient-icons">
      <v-fade-transition>
        <v-row no-gutters class="gradient-icons">
          <v-col
            style="
              position: fixed;
              z-index: 99;
              width: calc(100vw);
              background-color: #fff;
              margin-top: -11px;
              margin-left: -12px;
              padding: 10px 10px 2px;
            "
            cols="12"
            class="elevation-2"
          >
            <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
              <v-col cols="12" md="3" lg="2" v-if="timedToggle === 0" :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''">
                <date-picker
                  :input-styles="{
                    outlined: true,
                    dense: true,
                    solo: true,
                    singleLine: true,
                    hideDetails: true,
                    large: true,
                    class: 'site-month-picker',
                  }"
                  type="month"
                  class="site-month-picker"
                  :date="startDate"
                  prependInnerIcon="mdi mdi-calendar"
                  append-icon="filter_list"
                  :label="$t('sites.planned_start_date_starts')"
                  @change="onChangeStartDate"
                />
              </v-col>

              <v-col
                v-if="!$isTenantAdmin && !isUserRentOrEntrepreuner"
                cols="12"
                sm="12"
                md="3"
                class="site-toggle"
                :class="[{ 'mt-2': $vuetify.breakpoint.smAndDown }]"
              >
                <v-btn-toggle v-model="ownSite" dense mandatory style="width: 100%" class="elevation-3" dark>
                  <v-btn color="white" style="width: 50%" class="text-caption">
                    {{ $t('sites.own_sites') }}
                  </v-btn>
                  <v-btn color="white" style="width: 50%" class="text-caption">
                    {{ $t('sites.all_sites') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>

              <v-col
                v-if="$isTenantAdmin || $isTenantManager || $isTenantBasic"
                cols="12"
                sm="12"
                md="3"
                class="site-toggle"
                :class="[{ 'mt-2': $vuetify.breakpoint.smAndDown }]"
              >
                <v-btn-toggle v-model="timedToggle" mandatory dense style="width: 100%" class="elevation-3" dark>
                  <v-btn color="white" style="width: 50%" class="text-caption">
                    {{ $t('sites.scheduled_sites') }}
                  </v-btn>
                  <v-btn color="white" style="width: 50%" class="text-caption">
                    {{ $t('sites.unscheduled_sites') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" sm="12" md="2" class="site-toggle mt-n2" v-if="!timedToggle">
                <v-switch v-model="showNotReady" inset :label="$t('sites.showNotReady')"></v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="filter-padding" :class="[timedToggle === 1 && 'filter-padding-timed-toggle']"> </v-col>
          <v-col cols="12" class="mt-5" v-if="loading || subtleLoading">
            <hb-loading-indicator align-middle />
          </v-col>
          <v-col v-if="!subtleLoading && timedToggle === 0" cols="12" class="mt-15">
            <v-container class="pt-0" :pa-0="$vuetify.breakpoint.smAndDown" fluid>
              <v-row no-gutters :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0' : ''">
                <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0' : ''">
                  <v-fade-transition hide-on-leave>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div v-if="sites.length > 0 && !showNotReady && sites.length < 50">
                          <v-timeline :light="searchPhrase.length > 0" two-line color="transparent" class="pt-0" dense>
                            <template v-for="week in totalWeeksToShow">
                              <week-list-item-for-all-sites
                                :key="week.id"
                                :ref="`${week.id}`"
                                :week="week"
                                :filteredSites="filterSitesWithStartDate"
                                :searchPhrase="searchPhrase"
                                :showOnlyFilledWeeks="searchPhrase.length > 0"
                              />
                            </template>
                          </v-timeline>
                        </div>
                        <div v-else-if="sites.length > 0">
                          <v-data-table
                            :headers="headers"
                            :items="filterSitesWithStartDate"
                            :items-per-page="9999"
                            class="elevation-1"
                            @click:row="item => goToSite(item)"
                          >
                            <template v-slot:item.plannedStartDate="{ item }">
                              {{ $formatDateNoTime(item.plannedStartDate) }}
                            </template>
                            <template v-slot:item.name="{ item }">
                              <router-link :to="{ name: 'tenant_site_edit_site', params: { id: item.id.toString(), tenantId: $tenant } }">{{
                                [item.project && item.project.orderer && item.project.orderer.companyName, item.name]
                                  .filter(Boolean)
                                  .join(', ')
                              }}</router-link>
                            </template>
                            <template v-slot:item.plannedDuration="{ item }">
                              <v-chip v-if="item.plannedDuration" color="primary" small>{{ item.plannedDuration }} pv</v-chip>
                            </template>
                            <template v-slot:item.status="{ item }">
                              <v-chip v-if="item.status" :color="statusColor(item).color" small
                                >{{ item.status === null ? $t(`sites.statuses.no_status`) : $t(`sites.statuses.${item.status}`) }}
                              </v-chip>
                            </template>
                          </v-data-table>
                        </div>
                        <v-container v-else pa-0 fluid>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-row justify="center" align="center" no-gutters>
                                <div
                                  class="text-center pl-5 pr-5"
                                  style="padding-bottom: 150px"
                                  :style="{ paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px' }"
                                >
                                  <v-icon size="100">mdi mdi-shovel</v-icon>
                                  <div class="headline font-weight-bold mt-3 mb-3">{{ $t('sites.no_sites') }}</div>
                                  <div class="subtitle-1">{{ $t('sites.no_sites_help_for_tenant') }}</div>
                                  <v-btn @click="reloadSites" class="mt-5" outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
                                </div>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-col>

          <v-col v-if="!subtleLoading && timedToggle === 1" cols="12" class="mt-15">
            <v-container class="pt-0" :pa-0="$vuetify.breakpoint.smAndDown" fluid>
              <v-row no-gutters :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0' : ''">
                <v-col cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pa-0 ma-0' : ''">
                  <v-fade-transition hide-on-leave>
                    <v-row v-if="!loading" no-gutters>
                      <v-col cols="12" class="factory-review-requests-holder">
                        <div style="padding-bottom: 60px" v-if="sites.length > 0">
                          <v-list two-line color="transparent" class="pt-0">
                            <template v-for="(site, index) in filterSitesWithoutStartDate">
                              <v-divider v-if="index !== 0" :key="index" />
                              <work-sites-view-list-item :key="site.id + ' ' + site.name" :site="site" />
                            </template>
                          </v-list>
                        </div>
                        <v-container v-if="!loading && sites.length === 0" pa-0 fluid>
                          <v-row no-gutters>
                            <v-col cols="12">
                              <v-row justify="center" align="center" no-gutters>
                                <div
                                  class="text-center pl-5 pr-5"
                                  style="padding-bottom: 150px"
                                  :style="{ paddingTop: $vuetify.breakpoint.smAndUp ? '200px' : '50px' }"
                                >
                                  <v-icon size="100">mdi mdi-shovel</v-icon>
                                  <div class="headline font-weight-bold mt-3 mb-3">{{ $t('sites.no_sites') }}</div>
                                  <div class="subtitle-1">{{ $t('sites.no_sites_help_for_tenant') }}</div>
                                  <v-btn @click="reloadSites" class="mt-5" outlined><v-icon left>refresh</v-icon> {{ $t('reload') }}</v-btn>
                                </div>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-btn fab fixed bottom right v-if="$vuetify.breakpoint.xsOnly && $isTenantAdmin" @click="createSite" color="primary">
            <v-icon class="mr-0 no-gradient">mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </v-fade-transition>
    </v-col>
  </hb-basic-page>
</template>

<script>
import WeekListItemForAllSites from '@/components/Sites/WeekListItemForAllSites';
import siteApi from '@/api/site';
import dayjs from 'dayjs';
import WorkSitesViewListItem from '@/components/Sites/WorkSitesViewListItem';
import DatePicker from '@/components/DatePicker';
import workerApi from '@/api/worker';

const isoWeek = require('dayjs/plugin/isoWeek');
dayjs.extend(isoWeek);

export default {
  name: 'WorkSitesView',
  components: { DatePicker, WorkSitesViewListItem, WeekListItemForAllSites },
  data() {
    return {
      thisWeek: false,
      options: {
        duration: 500,
        offset: this.$vuetify.breakpoint.mdAndUp ? 60 : 130,
        easing: 'easeInOutCubic',
      },
      loading: true,
      subtleLoading: false,
      ownSite: 0,
      timedToggle: 0,
      searchPhrase: '',
      sites: [],
      startDate: dayjs().add(-2, 'weeks').startOf('month'),
      menu2: false,
      isUserRentOrEntrepreuner: false,
      showNotReady: false,
      paging: {
        page: 1,
        pageSize: 50,
      },
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Työmaa', value: 'name' },
        { text: 'Tilaaja', value: 'project.orderer.companyName' },
        { text: 'Pääurakoitsija', value: 'project.primeContractor.companyName' },
        { text: 'Työryhmä', value: 'workGroup.name' },
        { text: 'Tila', value: 'status' },
        { text: 'Suunniteltu aloitus', value: 'plannedStartDate' },
        { text: 'Arvioitu kesto', value: 'plannedDuration' },
      ];
    },
    filterSitesWithStartDate() {
      const sites = this.sitesWithStartDate;
      sites.sort((a, b) => {
        if (a.plannedStartDate.isBefore(b.plannedStartDate)) return -1;
        if (a.plannedStartDate.isAfter(b.plannedStartDate)) return 1;
        return 0;
      });
      if (this.searchPhrase && this.searchPhrase.length > 0) {
        const splitFilterText = this.searchPhrase.toLowerCase().split(' ');

        return sites.filter(k => {
          const found = [];
          const siteName = [k.name, k.project?.primeContractor?.companyName, k.project?.orderer?.companyName]
            .filter(Boolean)
            .join(' ')
            .toLowerCase();

          splitFilterText.forEach(word => {
            if (siteName.indexOf(word) > -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          });
          return found.indexOf(false) === -1;
        });
      }
      return sites /*.slice((this.paging.page - 1) * this.paging.pageSize, this.paging.page * this.paging.pageSize)*/;
    },
    filterSitesWithoutStartDate() {
      if (this.searchPhrase) {
        const splitFilterText = this.searchPhrase.toLowerCase().split(' ');

        return this.sitesWithoutStartDate.filter(k => {
          const found = [];
          const siteName = [k.name, k.project?.primeContractor?.companyName, k.project?.orderer?.companyName]
            .filter(Boolean)
            .join(' ')
            .toLowerCase();

          splitFilterText.forEach(word => {
            if (siteName.indexOf(word) > -1) {
              found.push(true);
            } else {
              found.push(false);
            }
          });
          return found.indexOf(false) === -1;
        });
      } else {
        return this.sitesWithoutStartDate;
      }
    },
    latestWeekToShow() {
      if (this.sitesWithStartDate.length > 0) {
        const listOfEndDates = this.sitesWithStartDate.map(t => t.plannedStartDate).filter(Boolean);
        let moments = listOfEndDates.map(d => dayjs(d));

        const maxDate = moments.reduce((accumulator, curVal) => (curVal && accumulator.isAfter(curVal) ? accumulator : curVal));
        return dayjs(maxDate).add(2, 'weeks');
      }
      return dayjs().endOf('year');
    },
    sitesWithoutStartDate() {
      if (this.ownSite === 0) {
        const ownSites = this.sites.filter(s => s.workers?.findIndex(w => w.userId === this.$userInfo.id) > -1);
        return ownSites.filter(s => !s.plannedStartDate);
      }
      return this.sites.filter(s => !s.plannedStartDate);
    },
    sitesWithStartDate() {
      if (this.ownSite === 0) {
        const ownSites = this.sites.filter(s => s.workers?.findIndex(w => w.userId === this.$userInfo.id) > -1);
        return ownSites.filter(s => s.plannedStartDate);
      }
      return this.sites.filter(s => s.plannedStartDate);
    },
    totalWeeksToShow() {
      let listOfWeeks = [];

      let currentDate;
      if (this.showNotReady) {
        const earliestStartDate = this.filterSitesWithStartDate
          .map(t => t.plannedStartDate)
          .filter(Boolean)
          .reduce((accumulator, curVal) => (curVal && accumulator !== null && accumulator?.isBefore(curVal) ? accumulator : curVal), null);
        currentDate = dayjs(earliestStartDate); //dayjs().add(-2, 'weeks');
      } else {
        currentDate = dayjs(this.startDate);
      }

      const weekDiff = this.latestWeekToShow.diff(currentDate, 'weeks');

      for (let i = 0; i < weekDiff; i++) {
        let weekStart = currentDate.clone().add(i, 'weeks').startOf('isoWeek');
        let weekEnd = currentDate.clone().add(i, 'weeks').endOf('isoWeek');

        listOfWeeks.push({
          week: dayjs(weekStart).isoWeek(),
          id: weekStart.format('W/YYYY'),
          weekStartDate: weekStart.format('DD.MM.YYYY'),
          weekEndDate: weekEnd.format('DD.MM.YYYY'),
        });
      }
      return listOfWeeks;
    },
  },
  methods: {
    statusColor(site) {
      switch (site.status) {
        case 'CREATED':
          return { color: 'light-blue', textColor: 'white' };
        case 'SCHEDULED':
          return { color: 'blue', textColor: 'white' };
        case 'INPROGRESS':
          return { color: 'orange', textColor: 'white' };
        case 'DONE':
          return { color: 'green', textColor: 'white' };
        default:
          return { color: 'pink', textColor: 'white' };
      }
      return {};
    },
    goToSite(site) {
      this.$router.push({ name: 'tenant_site_edit_site', params: { id: site.id.toString(), tenantId: this.$tenant } });
    },
    async getWorker() {
      const worker = await workerApi.getWorker(this.$userInfo.id);
      if (worker.relation === 'entrepreneur' || worker.relation === 'rent') {
        this.isUserRentOrEntrepreuner = true;
      } else {
        this.isUserRentOrEntrepreuner = false;
      }
    },
    log(...args) {
      console.log(args);
    },
    onChangeStartDate(e) {
      if (dayjs(e).isValid()) {
        this.startDate = dayjs(e).startOf('month');
        this.reloadSites();
      }
    },
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
    createSite() {
      this.$router.push({ name: 'tenant_site_new_site' });
    },
    async reloadSites() {
      let loadingTimeout = null;
      if (this.sites.length > 0) {
        loadingTimeout = setTimeout(() => {
          this.subtleLoading = true;
        }, 300);

        this.loading = false;
      } else {
        this.subtleLoading = false;
        this.loading = true;
      }

      try {
        const sites = await siteApi.getSitesWithFilters(
          this.timedToggle === 1 ? { startDate: null } : { startDate: this.startDate, showNotReady: this.showNotReady }
        );
        this.sites = sites.filter(s => s.status !== 'DELETED');
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.$showErrorNotification(this.$t('errors.error_loading_sites'), false);
      }
      if (loadingTimeout) {
        clearTimeout(loadingTimeout);
      }
      this.loading = false;
      this.subtleLoading = false;
    },
  },
  watch: {
    timedToggle() {
      this.reloadSites();
      /*if (!this.$isTenantAdmin) {
        this.timedToggle = 0;
      }*/
      if (this.isUserRentOrEntrepreuner) {
        this.ownSite = 0;
      }
      if (this.$isTenantAdmin) {
        this.ownSite = 1;
      }
    },
    showNotReady() {
      this.showNotReady ? localStorage.setItem('showNotReadySites', this.showNotReady) : localStorage.removeItem('showNotReadySites');
      this.reloadSites();
    },
  },
  async mounted() {
    this.loading = true;
    localStorage.getItem('showNotReadySites') ? (this.showNotReady = true) : (this.showNotReady = false);
    await this.getWorker();
    if (!this.$isTenantAdmin) {
      this.timedToggle = 0;
    }
    if (this.isUserRentOrEntrepreuner) {
      this.ownSite = 0;
    }
    if (this.$isTenantAdmin) {
      this.ownSite = 1;
    }
    await this.reloadSites();
    this.thisWeek = dayjs().format('W/YYYY');

    if (this.$refs[this.thisWeek] && this.$refs[this.thisWeek][0]) {
      await this.$vuetify.goTo(this.$refs[this.thisWeek][0], this.options);
    }
    this.loading = false;
  },
};
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/variables';
.filter-padding {
  padding-bottom: 30px !important;
}
@media #{map-get($display-breakpoints,'sm-and-down')} {
  .filter-padding {
    padding-bottom: 170px !important;
  }
  .filter-padding-timed-toggle {
    padding-bottom: 70px !important;
  }
  .worksites-list {
    .v-timeline-item {
      padding-bottom: 0;
    }
    .v-timeline {
      &:before {
        display: none !important;
      }

      &-item__body {
        max-width: 100% !important;

        .v-card {
          &:before,
          &:after {
            top: 0 !important;
            border: 0 !important;
          }
        }
      }

      .v-timeline-item__divider {
        display: none;
      }
    }
  }
}
.worksites-list {
  .site-toggle {
    .v-btn__content {
      color: #000;
    }
  }
  .site-month-picker {
    .v-input__slot {
      height: 48px !important;
    }
    fieldset {
      color: transparent !important;
      &:hover {
        color: transparent !important;
      }
    }
  }
}
</style>
