<template>
  <hb-basic-page
    v-if="$isTenantAdmin || $isTenantManager"
    :extension-height="60"
    :fill-height="loading"
    :loading="loading"
    :pa-0="$vuetify.breakpoint.xsOnly"
    :title="$t('reports.title')"
    class="reports-view"
    fluid
  >
    <template v-if="$vuetify.breakpoint.smAndUp" v-slot:app-bar-extension>
      <v-btn v-if="$isTenantAdmin && !COMBINED && !STATS" class="mb-3 mr-3" color="primary" @click="save">
        <v-icon class="no-gradient" left>mdi mdi-download-outline</v-icon>
        <span>{{ $t('reports.download_excel') }}</span>
      </v-btn>
      <v-btn v-if="$isTenantAdmin && COMBINED" class="mb-3 mr-3" color="primary" @click="downloadCombinedSalaryDataXlsx">
        <v-icon class="no-gradient" left>mdi mdi-download-outline</v-icon>
        <span>{{ $t('reports.download_excel') }}</span>
      </v-btn>
      <v-btn v-if="$isTenantAdmin" class="mb-3 mr-3" color="primary" @click="downloadSalaryDataCSV">
        <v-icon class="no-gradient" left>mdi mdi-download-outline</v-icon>
        <span>{{ $t('reports.download_salary_data') }}</span>
      </v-btn>

      <v-btn v-if="$isTenantAdmin" :disabled="selectedItemsToApprove.length <= 0" class="mb-3 mr-3" color="primary" @click="approve">
        <v-icon class="no-gradient" left>mdi mdi-check-all</v-icon>
        <span>{{ $t('reports.approve') }}</span>
      </v-btn>
      <v-btn v-if="$isTenantManager" :disabled="selectedItemsToApprove.length <= 0" class="mb-3" color="primary" @click="check">
        <v-icon class="no-gradient" left>mdi mdi-check</v-icon>
        <span>{{ $t('reports.check') }}</span>
      </v-btn>
      <v-btn v-if="false && $isTenantAdmin" class="mb-3 mr-3" color="primary" @click="createNewEntry()">
        <v-icon class="no-gradient" left>mdi mdi-clock</v-icon>
        <span>{{ $t('timer.create_new_entry') }}</span>
      </v-btn>
      <v-btn v-if="false && $isTenantAdmin" class="mb-3 mr-3" color="primary" @click="createNewMEntry()">
        <v-icon class="no-gradient" left>mdi mdi-wallet-travel</v-icon>
        <span>{{ $t('timer.create_new_travel_invoice') }}</span>
      </v-btn>
      <v-btn v-if="false && $isTenantAdmin" class="mb-3 mr-3" color="primary" @click="createNewAwayEntry()">
        <v-icon class="no-gradient" left>mdi mdi-run</v-icon>
        <span>{{ $t('timer.new_away_entry') }}</span>
      </v-btn>
    </template>

    <v-btn v-if="$vuetify.breakpoint.xsOnly" bottom color="primary" dark fab fixed left @click="save">
      <v-icon class="no-gradient">mdi mdi-download-outline</v-icon>
    </v-btn>
    <v-btn
      v-if="$isTenantAdmin && $vuetify.breakpoint.xsOnly"
      :disabled="selectedItemsToApprove.length <= 0"
      :style="{
        left: '83px',
      }"
      bottom
      color="primary"
      fab
      fixed
      left
      light
      @click="approve"
    >
      <v-icon class="no-gradient">mdi mdi-check-all</v-icon>
    </v-btn>
    <v-btn
      v-if="($isTenantAdmin || $isTenantManager) && $vuetify.breakpoint.xsOnly"
      :disabled="selectedItemsToApprove.length <= 0"
      :style="{
        left: '150px',
      }"
      bottom
      color="primary"
      fab
      fixed
      left
      light
      @click="check"
    >
      <v-icon class="no-gradient">mdi mdi-check</v-icon>
    </v-btn>
    <v-col class="gradient-icons" cols="12">
      <v-row>
        <v-col :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''" cols="12" md="3" sm="6">
          <v-menu ref="dateRangePicker" v-model="dateRangePicker" :close-on-content-click="false" offset-y transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filterDatesText"
                append-icon="filter_list"
                background-color=""
                hide-details
                readonly
                single-line
                solo
                v-on="on"
              >
                <template slot="prepend-inner">
                  <v-icon class="mr-2">event</v-icon>
                </template>
              </v-text-field>
            </template>
            <v-card>
              <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                <v-row no-gutters>
                  <!--<v-col cols="6"
                        ><v-btn text small block @click="selectThisWeek">{{ $t('reports.this_week') }}</v-btn></v-col
                      >
                      <v-col cols="6"
                        ><v-btn text small block @click="selectLastWeek">{{ $t('reports.last_week') }}</v-btn></v-col
                      >
                      <v-col cols="6"
                        ><v-btn text small block @click="selectThisMonth">{{ $t('reports.this_month') }}</v-btn></v-col
                      >
                      <v-col cols="6"
                        ><v-btn text small block @click="selectLastMonth">{{ $t('reports.last_month') }}</v-btn></v-col
                      >
                      <v-col cols="6"
                        ><v-btn text small block @click="selectThisYear">{{ $t('reports.this_year') }}</v-btn></v-col
                      >
                      <v-col cols="6"
                        ><v-btn text small block @click="selectLastYear">{{ $t('reports.last_year') }}</v-btn></v-col
                      >-->
                  <v-col v-for="dayRange in dayRanges" :key="dayRange.label" cols="12">
                    <v-btn block small text @click="filterDates = dayRange.dates">{{ dayRange.label }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
              <v-card-text>
                <v-row>
                  <div v-if="$vuetify.breakpoint.smAndUp" class="mr-1">
                    <!--<v-btn text small :block="$vuetify.breakpoint.smAndUp" @click="selectThisWeek">{{
                          $t('reports.this_week')
                        }}</v-btn>
                        <v-btn text small :block="$vuetify.breakpoint.smAndUp" @click="selectLastWeek">{{
                          $t('reports.last_week')
                        }}</v-btn>
                        <v-btn text small :block="$vuetify.breakpoint.smAndUp" @click="selectThisMonth">{{
                          $t('reports.this_month')
                        }}</v-btn>
                        <v-btn text small :block="$vuetify.breakpoint.smAndUp" @click="selectLastMonth">{{
                          $t('reports.last_month')
                        }}</v-btn>
                        <v-btn text small :block="$vuetify.breakpoint.smAndUp" @click="selectThisYear">{{
                          $t('reports.this_year')
                        }}</v-btn>
                        <v-btn text small :block="$vuetify.breakpoint.smAndUp" @click="selectLastYear">{{
                          $t('reports.last_year')
                        }}</v-btn>
                      -->

                    <v-btn
                      v-for="dayRange in dayRanges"
                      :key="dayRange.label"
                      :block="$vuetify.breakpoint.smAndUp"
                      small
                      text
                      @click="filterDates = dayRange.dates"
                      >{{ dayRange.label }}
                    </v-btn>
                  </div>
                  <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
                  <v-date-picker
                    v-model="filterDates"
                    :full-width="$vuetify.breakpoint.xsOnly"
                    class="elevation-0"
                    color=""
                    locale="fi"
                    no-title
                    range
                    scrollable
                    show-week
                    :first-day-of-week="1"
                    :locale-first-day-of-year="4"
                  ></v-date-picker>
                </v-row>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>

        <v-col :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''" cols="12" md="3" sm="6">
          <v-autocomplete
            v-model="filterSite"
            :disabled="COMBINED || STATS"
            :items="sites"
            :placeholder="$t('reports.site')"
            clearable
            hide-details
            item-text="name"
            item-value="id"
            multiple
            single-line
            solo
          >
            <template slot="append">
              <v-icon :class="COMBINED || STATS ? 'no-gradient' : ''" :color="COMBINED || STATS ? 'grey lighten-1' : ''"
                >filter_list
              </v-icon>
            </template>
            <template slot="prepend-inner">
              <v-icon :class="COMBINED || STATS ? 'no-gradient' : ''" :color="COMBINED || STATS ? 'grey lighten-1' : ''" class="mr-2"
                >mdi mdi-shovel
              </v-icon>
            </template>
            <template v-slot:selection="{ item, index }">
              <template v-if="index === 0 && filterSite.length === 1">
                <span>{{ item.name }}</span>
              </template>
              <template v-if="filterSite.length > 1 && index === 0">
                <span>{{ filterSite.length }} {{ $t('reports.selections') }}</span>
              </template>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col :class="$vuetify.breakpoint.smAndDown ? 'pb-0' : ''" cols="12" md="3" sm="6">
          <v-select
            v-model="filterType"
            :items="timeEntryTypes"
            :placeholder="$t('reports.types')"
            append-icon="filter_list"
            background-color=""
            clearable
            hide-details
            item-text="label"
            item-value="value"
            single-line
            solo
          >
            <template slot="prepend-inner">
              <v-icon class="mr-2">mdi mdi-alpha-t</v-icon>
            </template>
          </v-select>
        </v-col>

        <v-col cols="12" md="3" sm="6">
          <v-autocomplete
            v-model="filterUser"
            :disabled="STATS"
            :items="richerUsers"
            :placeholder="`${$t('reports.employee')}`"
            append-icon="filter_list"
            background-color=""
            clearable
            hide-details
            item-text="combinedInfo"
            item-value="id"
            multiple
            single-line
            solo
          >
            <template slot="append">
              <v-icon :class="STATS ? 'no-gradient' : ''" :color="STATS ? 'grey lighten-1' : ''">filter_list</v-icon>
            </template>
            <template slot="prepend-inner">
              <v-icon :class="STATS ? 'no-gradient' : ''" :color="STATS ? 'grey lighten-1' : ''" class="mr-2">people </v-icon>
            </template>
            <template v-slot:selection="{ item, index }">
              <template v-if="index === 0 && filterUser.length === 1">
                <span v-if="item.lastName">{{ item.lastName }} {{ item.firstName }}</span>
                <span v-else>{{ item.email }}</span>
              </template>
              <template v-if="filterUser.length > 1 && index === 0">
                <span>{{ filterUser.length }} {{ $t('reports.selections') }}</span>
              </template>
            </template>
            <template v-slot:item="data">
              <v-list-item-title>
                <template v-if="data.item.lastName">{{ data.item.lastName }} {{ data.item.firstName }}</template>
                <template v-else>{{ data.item.email }}</template>
              </v-list-item-title>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col :style="$vuetify.breakpoint.mdAndDown && { paddingBottom: '100px' }" class="pt-0">
          <template v-if="!loading">
            <CombinedSalaryDataForUser
              v-if="COMBINED && filterUser && filterUser.length === 1"
              :salary-data="salaryData"
              :user-id="filterUser[0]"
            />

            <CombinedSalaryDataTable
              v-if="COMBINED && filterUser.length > 0"
              ref="combinedSalaryDataTable"
              :entries="filteredEntries"
              :filters="{ filterDates, filterSite, filterUser }"
              :loading="loadingSalaryData || loadingEntries"
              :loadingApprove="loadingApprove"
              :salaryData="salaryData"
              @handle-selected-items="handleSelectedItems"
              @new-entry-for-user-and-day="createNewEntryForUserAndDate"
              @open-entry="openEntryInDialog"
              @entry-updated="handleUpdatedM0Entry"
            />
            <template v-else-if="STATS">
              <CombinedSalaryDataForUser v-for="user in users" :key="user.id" :salary-data="salaryData" :user-id="user.id" />
            </template>

            <v-data-table
              v-else-if="!COMBINED && !STATS"
              id="entries-table"
              v-model="selectedItems"
              :headers="TRAVELINVOICE ? travelInvoiceHeaders : timeEntryHeaders"
              :items="filteredEntries"
              :mobile-breakpoint="0"
              :no-data-text="$t('reports.no_entries')"
              :sort-by.sync="sortBy"
              :sort-desc.sync="descending"
              class="gradient-info__table"
              disable-pagination
              hide-default-footer
              item-key="id"
              locale="fi-FI"
              show-select
              style="cursor: pointer"
            >
              <template v-slot:body="{ items }">
                <template v-if="items.length > 0">
                  <tbody v-for="item in items" :key="item.id">
                    <tr class="bottom-border" @click="openEntryInDialog(item, false, false)">
                      <td :title="getItemCheckColumnTitle(item)">
                        <span class="d-none">{{ getItemCheckColumnTitle(item) }}</span>
                        <v-icon v-if="item.checked && !item.approved" class="no-gradient" color="success">mdi mdi-check </v-icon>
                        <v-icon v-if="item.approved" class="no-gradient" color="success">mdi mdi-check-all</v-icon>
                        <v-checkbox
                          v-if="
                            (!item.checked && $isTenantManager && !$isTenantAdmin && !item.approved) ||
                            (!item.approved && $isTenantAdmin && !loadingApprove) ||
                            (loadingApprove && selectedItems.findIndex(i => i.id === item.id) === -1)
                          "
                          v-model="selectedItems"
                          :value="item"
                          class="mt-0 mb-1"
                          hide-details
                          @click.stop
                        >
                        </v-checkbox>
                        <loading-indicator
                          v-if="!(!loadingApprove || (loadingApprove && selectedItems.findIndex(i => i.id === item.id) === -1))"
                          :size="24"
                        />
                      </td>
                      <td style="white-space: nowrap">
                        <span v-if="TRAVELINVOICE">
                          {{ item.entryStart ? $formatWeekDay(item.entryStart) : '' }}
                          {{ item.entryStart ? $formatDateNoTime(item.entryStart) : '' }}
                        </span>
                        <span v-else>
                          {{ item.entryStart ? $formatWeekDay(item.entryStart) : '' }}
                          {{ item.entryStart ? $formatDateNoTime(item.entryStart) : '' }}
                        </span>
                      </td>
                      <td v-if="TRAVELINVOICE">{{ item.timeEntryType ? item.timeEntryType : '' }}</td>
                      <td>
                        <span v-if="item.timeEntryType !== 'M0'">{{ item.site ? item.site.name : $t('timer.entry_has_no_site') }}</span>
                      </td>
                      <td>{{ item.user }}</td>
                      <td v-if="TRAVELINVOICE">
                        <template v-for="(equipment, index) in item.equipments">
                          <div :key="index">
                            <v-chip :key="equipment.id" :class="[index === item.equipments.length - 1 ? 'mb-2 mt-2' : 'mt-2']">
                              {{ equipment.make }} {{ equipment.licenseNumber }}&nbsp;{{ equipment.type && equipment.type.name }}
                            </v-chip>
                            <span v-if="index !== item.equipments.length - 1" class="hidden-xlsx-only">,&nbsp;</span>
                            <br />
                          </div>
                        </template>
                      </td>
                      <td>
                        <span v-if="item._notifyComments"
                          ><v-icon class="no-gradient notify-bell" color="warning">mdi mdi-bell-alert</v-icon>
                        </span>
                        {{ item.info }}
                      </td>
                      <td v-if="!TRAVELINVOICE">{{ defaultWorkDay(item) }}</td>
                      <td>
                        <span v-if="item.timeEntryType !== 'M0'"
                          >{{ $formatTimeNoDate(item.entryStart) }} - {{ $formatTimeNoDate(item.entryEnd) }}</span
                        >
                      </td>
                      <td>
                        <span v-if="item.timeEntryType !== 'M0'">{{ duration(item.duration) }}</span>
                      </td>

                      <td v-if="!TRAVELINVOICE">
                        <span class="hidden-xlsx-only">{{ item.containsLunch ? 'X' : '' }} </span>
                        <v-simple-checkbox :value="item.containsLunch" disabled readonly></v-simple-checkbox>
                      </td>

                      <td v-if="!TRAVELINVOICE">
                        {{ item.workType ? item.workType.name : '' }}
                      </td>

                      <td v-if="TRAVELINVOICE">
                        <div v-if="item.timeEntryType === 'M0'">
                          <span class="hidden-xlsx-only">{{ item.lunchPay ? 'X' : '' }} </span>
                          <v-simple-checkbox :value="item.lunchPay" disabled readonly />
                        </div>
                      </td>
                      <td v-if="TRAVELINVOICE">
                        <div v-if="item.timeEntryType === 'M0'">
                          <span class="hidden-xlsx-only">{{ item.overnightStayInCar ? 'X' : '' }} </span>
                          <v-simple-checkbox :value="item.overnightStayInCar" disabled readonly />
                        </div>
                      </td>
                      <td v-if="TRAVELINVOICE">
                        <div v-if="item.timeEntryType === 'M0'">
                          <span class="hidden-xlsx-only">{{ item.partDayPay ? 'X' : '' }} </span>
                          <v-simple-checkbox :value="item.partDayPay" disabled readonly />
                        </div>
                      </td>
                      <td v-if="TRAVELINVOICE">
                        <div v-if="item.timeEntryType === 'M0'">
                          <span class="hidden-xlsx-only">{{ item.homeCountryDayPay ? 'X' : '' }} </span>
                          <v-simple-checkbox :value="item.homeCountryDayPay" disabled readonly />
                        </div>
                      </td>
                      <td v-if="TRAVELINVOICE">
                        <div v-if="item.timeEntryType === 'M0'">
                          <span class="hidden-xlsx-only">{{ item.foreignCountryDayPay ? 'X' : '' }} </span>
                          <v-simple-checkbox :value="item.foreignCountryDayPay" disabled readonly />
                        </div>
                      </td>

                      <td v-if="!TRAVELINVOICE">
                        <span v-if="item.diameter">{{ item.diameter.name }}</span>
                      </td>
                      <td v-if="!TRAVELINVOICE">{{ item.length }}</td>
                      <!--<td class="text-center">
                    <v-icon v-if="item.invoiced" size="16">fas fa-euro-sign</v-icon>
                  </td>-->
                      <td>
                        <v-badge
                          v-if="item.imageCount > 0"
                          :content="item.imageCount"
                          :value="item.imageCount"
                          color="accent darken-2"
                          overlap
                        >
                          <v-icon color="white" size="28">collections</v-icon>
                        </v-badge>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <tbody v-else>
                  <tr>
                    <td :colspan="TRAVELINVOICE ? 16 : 13" class="text-center grey--text">
                      <span>{{ $t('reports.no_entries') }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </template>
        </v-col>
      </v-row>

      <entry-dialog
        ref="entryDialog"
        :cancel-button-text="$t('close')"
        :dialog-type="TRAVELINVOICE ? 'travelinvoice' : 'timeentry'"
        :equipments-array="equipments"
        :free-times="freeTimes"
        :items-array="items"
        :sites-array="sites"
        :users="users"
        admin-mode
        @dismiss="dialogDismiss"
      />
      <add-away-entry-dialog ref="addAwayEntryDialog" :users="users" admin-mode @away-entry-changed="awayEntryChange" />
    </v-col>
  </hb-basic-page>
</template>

<script>
import XLSX from 'xlsx';
import xlsxHelper from '../../plugins/xlsxhelper.plugin.js';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import EntryDialog from '../../components/Timer/EntryDialog.vue';

import siteApi from '@/api/site';
import equipmentApi from '@/api/equipment';
import timeentryApi from '@/api/timeentries';
import workgroupApi from '@/api/workGroup';
import itemsApi from '@/api/item';
import LoadingIndicator from '@/components/LoadingIndicator';
import filenameValidator from '@/plugins/filenamevalidator.plugin';
import downloadjs from 'downloadjs';
import CombinedSalaryDataTable from '../../components/Report/CombinedSalaryDataTable';
import CombinedSalaryDataForUser from '../../components/Report/CombinedSalaryDataForUser';
import AddAwayEntryDialog from '@/components/Timer/AddAwayEntryDialog';
import orderBy from 'lodash/orderBy';
import { isBoolean } from 'lodash/lang';

const ExcelJS = require('exceljs');
const numeral = require('numeral');

export default {
  name: 'ReportView',
  components: {
    AddAwayEntryDialog,
    CombinedSalaryDataForUser,
    CombinedSalaryDataTable,
    LoadingIndicator,
    EntryDialog: EntryDialog,
  },
  data() {
    return {
      placeHolder: null,
      sortBy: 'entryStartTS',
      descending: true,
      loading: true,
      loadingApprove: false,
      loadingEntries: false,
      loadingSalaryData: false,
      subtleLoading: false,
      searchPhrase: '',
      sites: [],
      dayRanges: [],
      equipments: [],
      allEntries: [],
      salaryData: [],
      items: [],
      selectedItems: [],
      timeEntryTypes: [
        { label: this.$t('timer.types.COMBINED'), value: 'COMBINED' },
        { label: this.$t('timer.types.STATS'), value: 'STATS' },
        { label: this.$t('timer.types.TIMEENTRY'), value: 'TIMEENTRY' },
        { label: this.$t('timer.types.TRAVELINVOICE'), value: 'TRAVELINVOICE' },
      ],
      filterSite: [],
      filterEquipment: [],
      filterUser: [],
      filterType: undefined,
      filterDates: [],
      filterInvoiced: undefined,
      dateRangePicker: false,
      users: [],
      loadingDownload: false,
      freeTimes: [],
      timeEntryHeaders: [
        {
          text: this.$t('reports.date'),
          value: 'entryStartTS',
        },
        {
          text: this.$t('reports.site'),
          value: 'site.name',
        },
        {
          text: this.$t('reports.employee'),
          value: 'user',
        },
        {
          text: this.$t('reports.info'),
          sortable: false,
        },
        {
          text: this.$t('reports.default_work_hours'),
          sortable: false,
        },
        {
          text: this.$t('reports.start_end'),
          sortable: false,
        },
        {
          text: this.$t('reports.duration'),
          sortable: false,
        },
        {
          text: this.$t('reports.containsLunch'),
          sortable: false,
        },
        {
          text: this.$t('timer.work_type'),
          value: 'workType',
        },
        {
          text: this.$t('timer.diameter'),
          value: 'diameter.name',
        },
        {
          text: this.$t('timer.length'),
          value: 'length',
        },
        /*{
          text: this.$t('reports.distance'),
          sortable: false,
        },*/
        /* {
            text: this.$t('reports.invoiced'),
            value: 'invoiced',
            sortable: true,
          }, */
        {
          text: '',
          sortable: false,
        },
      ],

      travelInvoiceHeaders: [
        {
          text: this.$t('reports.date'),
          value: 'entryStartTS',
        },
        {
          text: this.$t('reports.travel_invoice_type'),
          value: 'timeEntryType',
        },
        {
          text: this.$t('reports.site'),
          value: 'site.name',
        },
        {
          text: this.$t('reports.employee'),
          value: 'user',
        },
        {
          text: this.$t('reports.equipment'),
          value: 'equipmentString',
          divider: true,
        },
        {
          text: this.$t('reports.info'),
          sortable: false,
        },
        {
          text: this.$t('reports.time'),
          sortable: false,
        },
        {
          text: this.$t('reports.duration'),
          sortable: false,
        },
        {
          text: this.$t('reports.lunch_pay'),
          sortable: false,
        },
        {
          text: this.$t('reports.overnight_stay_in_car'),
          sortable: false,
        },
        {
          text: this.$t('reports.part_day_pay'),
          sortable: false,
        },
        {
          text: this.$t('reports.whole_day_pay'),
          sortable: false,
        },
        {
          text: this.$t('reports.foreign_country_day_pay'),
          sortable: false,
        },
        /* {
            text: this.$t('reports.invoiced'),
            value: 'invoiced',
            sortable: true,
          }, */ {
          text: '',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    async awayEntryChange(status) {
      await this.getSalaryData();
      await this.reloadEntries();

      if (status) {
        switch (status) {
          case 'created':
            return this.$showSuccessNotification(this.$t('timer.away_entry_created'));
          case 'updated':
            return this.$showSuccessNotification(this.$t('timer.away_entry_updated'));
          case 'deleted':
            return this.$showSuccessNotification(this.$t('timer.entry_deleted'));
        }
      }
    },
    async dialogDismiss() {
      await this.getSalaryData();
      await this.reloadEntries();
    },
    handleSelectedItems(selectedItems) {
      this.selectedItems = selectedItems;
    },
    async handleUpdatedM0Entry(entry) {
      const index = this.allEntries.findIndex(e => e.id === entry.id);
      if (index > -1) {
        const placeholder = this.allEntries.filter(e => e.id !== entry.id);
        this.allEntries = [...placeholder, entry];
      } else {
        this.allEntries = [...this.allEntries, entry];
      }
      await this.getSalaryData();
    },
    duration(duration) {
      return duration ? numeral(Number(duration)).format('0.0') : '';
    },
    defaultWorkDay(entry) {
      if (entry.worker?.workHours > 0) {
        return entry.worker?.workHours;
      } else {
        return this.$t('reports.is_not_set');
      }
    },
    createNewEntry() {
      this.$refs.entryDialog.openNew(null, 'timeentry');
    },
    createNewMEntry() {
      this.$refs.entryDialog.openNew(null, 'travelinvoice');
    },
    createNewAwayEntry() {
      this.$refs.addAwayEntryDialog.open();
    },
    createNewEntryForUserAndDate(userId, date, entries) {
      this.$refs.entryDialog.openNew(date, 'timeentry', userId, entries);
    },
    openEntryInDialog(entry) {
      if (entry.timeEntryType === 'AWAYENTRY') {
        this.$refs.addAwayEntryDialog.openEntry(entry);
      } else {
        this.$refs.entryDialog.openEntry(entry);
      }
    },
    createPayPeriods() {
      const r = this.$createPayPeriods();
      if (this.filterDates === []) {
        this.filterDates = r.filterDates;
      }
      this.dayRanges = r.dayRanges;
    },
    async approve() {
      this.loadingApprove = true;
      await timeentryApi.massUpdateApproved({
        approved: true,
        entryIds: this.selectedItems.map(i => i.id).filter(Boolean),
      });
      this.selectedItems = [];
      if (this.$refs.combinedSalaryDataTable) {
        this.$refs.combinedSalaryDataTable.resetSelectedItems();
      }
      await this.reloadEntries();
      this.loadingApprove = false;
    },
    async check() {
      this.loadingApprove = true;
      await timeentryApi.massUpdateChecked({
        checked: true,
        entryIds: this.selectedItems.map(i => i.id).filter(Boolean),
      });
      this.selectedItems = [];
      if (this.$refs.combinedSalaryDataTable) {
        this.$refs.combinedSalaryDataTable.resetSelectedItems();
      }
      await this.reloadEntries();
      this.loadingApprove = false;
    },
    save() {
      const element = document.querySelector('#entries-table table');
      const workBook = XLSX.utils.table_to_book(element, { sheet: this.$t('reports.title'), raw: true });

      //const workSheet = workBook.Sheets[0];

      const headers = this.TRAVELINVOICE ? this.travelInvoiceHeaders : this.timeEntryHeaders;
      // Delete last column (image count) before export
      if (!this.TRAVELINVOICE) xlsxHelper.deleteColumns(workBook.Sheets[workBook.SheetNames[0]], headers.length + 1, 1);

      const wbOut = XLSX.write(workBook, { bookType: 'xlsx', bookSST: true, type: 'binary', raw: true });

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      }

      const fileName = `${this.filterDatesText} ${this.$t('title')} ${this.$t('reports.title')}${this.getSiteNames()}.xlsx`;

      saveAs(new Blob([s2ab(wbOut)], { type: 'application/octet-stream' }), fileName);
    },
    async getSalaryData() {
      this.loadingSalaryData = true;
      let filterDates = [...this.filterDates];
      filterDates = filterDates.map((d, i) => {
        if (i === 0) {
          return dayjs(d).startOf('day').toISOString();
        } else {
          return dayjs(d).endOf('day').toISOString();
        }
      });
      try {
        const response = await timeentryApi.getSalaryData({ dates: filterDates });
        this.salaryData = response;
      } catch (error) {
        console.error(error);
        this.$showErrorNotification(this.$t('reports.errors.error_loading_salary_data'));
      }
      this.loadingSalaryData = false;
    },
    getSiteNames() {
      const filterSite = [];
      this.filterSite.forEach(siteId => {
        const siteIndex = this.sites.findIndex(site => parseInt(site.id, 10) === parseInt(siteId, 10));
        if (siteIndex > -1) {
          filterSite.push(this.sites[siteIndex].name);
        }
      });
      if (filterSite.length > 0) {
        return ' - ' + filterSite.join(' ');
      }
      return '';
    },
    removeDuplicates(array) {
      let uniqueArray = [];
      array.forEach(function (element) {
        let isDuplicate = uniqueArray.some(function (uniqueElement) {
          return element.id === uniqueElement.id;
        });
        if (!isDuplicate) {
          uniqueArray.push(element);
        }
      });
      return uniqueArray;
    },
    async downloadCombinedSalaryDataXlsx() {
      this.loadingDownload = true;

      const workbook = new ExcelJS.Workbook();
      workbook.created = new Date();
      workbook.modified = new Date();

      const users = this.removeDuplicates(
        this.filteredEntries.map(entry => ({
          id: entry.userId,
          name: entry.user,
        }))
      ).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

      users.forEach(user => {
        const worksheet = workbook.addWorksheet(user.name, {
          pageSetup: { paperSize: 9, orientation: 'landscape' },
        });
        worksheet.columns = [
          { header: 'Pvm', key: 'date', width: 9, outlineLevel: 1 },
          { header: 'Vp', key: 'weekday', width: 4, outlineLevel: 1 },
          { header: 'Työmaa', key: 'site', width: 20, outlineLevel: 1 },
          { header: 'Työntekijä', key: 'name', width: 14, outlineLevel: 1 },
          { header: 'Lisätiedot', key: 'info', width: 10, outlineLevel: 1 },
          { header: 'Työaika', type: 'decimal', key: 'time', width: 9, outlineLevel: 1 },
          { header: 'M1', type: 'decimal', key: 'm1', width: 5, outlineLevel: 1 },
          { header: 'M2', type: 'decimal', key: 'm2', width: 5, outlineLevel: 1 },
          { header: 'Kest', key: 'duration', width: 6, outlineLevel: 1 },
          { header: 'Loun', key: 'containsLunch', width: 4, outlineLevel: 1 },
          { header: 'Työt', key: 'work_type', width: 11, outlineLevel: 1 },
          { header: 'Put. halk.', key: 'diameter', width: 6, outlineLevel: 1 },
          { header: 'Por. pit.', key: 'length', width: 5, outlineLevel: 1 },
          { header: 'Ruoka', key: 'lunch_pay', width: 6, outlineLevel: 1 },
          { header: 'Yö', key: 'overnight_stay_in_car', width: 4, outlineLevel: 1 },
          { header: 'Opr', key: 'part_day_pay', width: 4, outlineLevel: 1 },
          { header: 'Kpr', key: 'whole_day_pay', width: 4, outlineLevel: 1 },
          { header: 'Upr', key: 'foreign_country_day_pay', width: 4, outlineLevel: 1 },
        ];
        const dayPayEntries = ['TIMEENTRY', 'M1', 'M2', 'AWAYENTRY'];
        const data = this.filteredEntries
          .filter(e => e.userId === user.id && dayPayEntries.includes(e.timeEntryType))
          .map(e => ({
            date: dayjs(e.entryStart).format('DD.MM.YYYY'),
            weekday: `${dayjs(e.entryStart).format('dd').capitalize()}`,
            site: [e.site?.project?.orderer?.companyName, e.site?.name].filter(Boolean).join(', '),
            name: e.user,
            id: e.userId,
            info: e.info,
            time: [this.$formatTimeNoDate(e.entryStart), this.$formatTimeNoDate(e.entryEnd)].filter(Boolean).join('-'),
            duration: e.duration,
            containsLunch: !!e.containsLunch,
            work_type: e.workType?.name,
            diameter: e.diameter?.name,
            length: e.length,
            lunch_pay: !!e.lunchPay,
            overnight_stay_in_car: null,
            part_day_pay: null,
            whole_day_pay: null,
            foreign_country_day_pay: null,
            timeEntryType: e.timeEntryType /* not displayed in excel, only used for day pay assignment */,
          }));

        // 1. haetaan jokaisen päivän ja henkilön pisin time entry tai mikäli ei löydy niin pisin entry.
        // 2. edellisen kohdan datan riveille asetetaan salarydata.
        const maxDurationForUserPerDate = {};
        data.forEach((entry, index) => {
          if (!dayPayEntries.includes(entry.timeEntryType) || !entry.duration) return;
          const key = `${entry.date}-${entry.id}`;
          if (!(key in maxDurationForUserPerDate)) {
            maxDurationForUserPerDate[key] = {
              date: entry.date,
              duration: parseFloat(entry.duration),
              type: entry.timeEntryType,
              id: entry.id,
              index: index,
            };
          } else if (dayPayEntries.indexOf(maxDurationForUserPerDate[key].type) >= dayPayEntries.indexOf(entry.timeEntryType)) {
            /* Priorisoidaan kirjauksen tyyppi: TIMEENTRY > M1 > M2.
             *  Etsitään priorisoidun tyypin pisin kirjaus */
            if (maxDurationForUserPerDate[key].duration < parseFloat(entry.duration)) {
              maxDurationForUserPerDate[key] = {
                date: entry.date,
                duration: parseFloat(entry.duration),
                type: entry.timeEntryType,
                id: entry.id,
                index: index,
              };
            }
          }
        });
        Object.keys(maxDurationForUserPerDate).forEach(key => {
          const entryRowForSalary = this.salaryData.salaryData.find(
            d =>
              dayjs(d.date).format('DD.MM.YYYY') === maxDurationForUserPerDate[key].date && d.user.id === maxDurationForUserPerDate[key].id
          );
          data[maxDurationForUserPerDate[key].index].whole_day_pay = entryRowForSalary.wholeDayPay !== 0;
          data[maxDurationForUserPerDate[key].index].lunch_pay = entryRowForSalary.lunchPay !== 0;
          data[maxDurationForUserPerDate[key].index].foreign_country_day_pay = entryRowForSalary.foreignCountryDayPay !== 0;
          data[maxDurationForUserPerDate[key].index].part_day_pay = entryRowForSalary.partDayPay !== 0;
          data[maxDurationForUserPerDate[key].index].overnight_stay_in_car = entryRowForSalary.overnightStayInCarPay !== 0;
        });

        /* Siirretään työajan kesto oikeaan sarakkeeseen jos kyseessä on matkakirjaus */
        data.forEach(entry => {
          entry.duration = +entry.duration;
          const tet = entry.timeEntryType;
          if (tet === 'M1') {
            entry.m1 = entry.duration;
            entry.duration = null;
          } else if (tet === 'M2') {
            entry.m2 = entry.duration;
            entry.duration = null;
          }
        });

        worksheet.addRows(data);

        const dates = Array.from(new Set(data.map(r => r.date)));
        const datesCol = worksheet.getColumnKey('date').values;
        let lastRowOfDate = [];

        dates.forEach(d => {
          lastRowOfDate.push(datesCol.lastIndexOf(`${d}`) - 1);
        });

        const endRow = worksheet.lastRow._number + 1;
        const summary = worksheet.getRow(endRow);
        const sumCols = ['I', 'G', 'H'];
        const countCols = ['R', 'M', 'N', 'O', 'P', 'Q'];

        summary.font = {
          ...summary.font,
          size: 9,
          bold: true,
        };
        summary.alignment = {
          horizontal: 'right',
          vertical: 'center',
        };
        summary.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD9D9D9' },
        };

        worksheet.getCell(`A${endRow}`).value = 'Yhteensä';
        sumCols.forEach(c => {
          worksheet.getCell(`${c}${endRow}`).value = { formula: `SUM(${c}2:${c}${endRow - 1})` };
        });
        countCols.forEach(c => {
          worksheet.getCell(`${c}${endRow}`).value = { formula: `COUNTA(${c}2:${c}${endRow - 1})` };
        });

        /* muunnetaan boolean-tyyppiset arvot joko 'X' tai tyhjä lukemisen helpottamiseksi */
        const boolCols = [
          worksheet.getColumn('containsLunch'),
          worksheet.getColumn('lunch_pay'),
          worksheet.getColumn('overnight_stay_in_car'),
          worksheet.getColumn('part_day_pay'),
          worksheet.getColumn('whole_day_pay'),
          worksheet.getColumn('foreign_country_day_pay'),
        ];
        boolCols.forEach(col =>
          col.eachCell(cell => {
            if (isBoolean(cell.value)) {
              cell.value = cell.value ? 'X' : null;
              cell.alignment = { horizontal: 'right' };
            }
          })
        );

        const rows = worksheet.getRows(1, worksheet.lastRow._number);
        rows.forEach(function (r, rowNumber) {
          if (rowNumber === 0) {
            r.eachCell(
              { includeEmpty: true },
              c =>
                (c.style = {
                  ...c.style,
                  border: { left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'double' } },
                  font: summary.font,
                  alignment: { wrapText: true },
                })
            );
          } else if (lastRowOfDate.some(val => val === rowNumber)) {
            r.eachCell(
              { includeEmpty: true },
              c =>
                (c.style = {
                  ...c.style,
                  border: { left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'thin' } },
                  font: { ...c.font, size: 9 },
                  alignment: { wrapText: true },
                })
            );
          } else {
            r.eachCell(
              { includeEmpty: true },
              c =>
                (c.style = {
                  ...c.style,
                  border: { left: { style: 'thin' }, right: { style: 'thin' }, bottom: { style: 'hair' } },
                  font: { ...c.font, size: 9 },
                  alignment: { wrapText: true },
                })
            );
          }
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer]);
      const tenant = await this.$tenantApi.getCurrentTenant();

      downloadjs(
        blob,
        filenameValidator(
          [`${this.$formatDateNoTime(this.filterDates[0])}-${this.$formatDateNoTime(this.filterDates[1])}`, tenant.name, 'Työkirjaukset']
            .filter(Boolean)
            .join('_')
            .concat('.xlsx')
        )
      );
      this.loadingDownload = false;
    },
    async downloadSalaryDataCSV() {
      this.loadingDownload = true;
      let filterDates = [...this.filterDates];
      filterDates = filterDates.map((d, i) => {
        if (i === 0) {
          return dayjs(d).startOf('day').toISOString();
        } else {
          return dayjs(d).endOf('day').toISOString();
        }
      });
      const csv = await timeentryApi.getSalaryDataCSV({ dates: filterDates });
      const tenant = await this.$tenantApi.getCurrentTenant();
      downloadjs(
        csv,
        filenameValidator([tenant.name, 'procountor_salary_data', dayjs().format('YYYY-MM-DD')].filter(Boolean).join('_').concat('.zip'))
      );
      this.loadingDownload = false;
    },
    async reloadCloudUsers() {
      try {
        let users;
        if (this.$isTenantAdmin) {
          users = await this.$api.getCloudUsers();
        } else if (this.$isTenantManager) {
          users = await workgroupApi.getOwnWorkGroupUsers();
        } else {
          users = [this.$userInfo];
        }

        this.users = users;
      } catch (error) {
        this.$store.dispatch('showNotification', {
          color: 'error',
          message: this.$t('errors.error_loading_users'),
          timeOut: 10000,
          showButton: true,
          buttonText: this.$t('try_again'),
          callback: () => {
            this.reloadCloudUsers();
          },
        });
        console.log(error);
      }
    },
    async loadImages(entryId) {
      try {
        const fileEntities = await this.$api.getEntryImages(entryId);
        return fileEntities;
      } catch (error) {
        console.log(error);
      }
      return [];
    },
    getItemCheckColumnTitle(item) {
      let title = '';
      if (item.approved) {
        const approvedDateAndUser = [
          item.approvedUser && [item.approvedUser.lastName, item.approvedUser.firstName].join(' '),
          this.$formatDateTime(item.approved),
        ]
          .filter(Boolean)
          .join(', ');
        title = `${this.$t('reports.approved')}: ` + approvedDateAndUser;
      }
      if (item.checked) {
        if (item.approved) title = title + ' - ';
        const checkedDateAndUser = [
          item.checkedUser && [item.checkedUser.lastName, item.checkedUser.firstName].join(' '),
          this.$formatDateTime(item.checked),
        ]
          .filter(Boolean)
          .join(', ');
        title = title + `${this.$t('reports.checked')}: ` + checkedDateAndUser;
      }
      return title;
    },
    async reloadAll() {
      if (this.entries.length === 0) {
        this.loading = true;
        this.subtleLoading = false;
      } else {
        this.loading = false;
        this.subtleLoading = true;
      }

      await Promise.all([
        (async () => {
          await this.reloadCloudUsers();
        })(),
        (async () => {
          this.sites = await siteApi.getSites();
        })(),
        (async () => {
          this.equipments = await equipmentApi.getAllEquipments();
        })(),
        (async () => {
          this.items = await itemsApi.getItemsByTypes(['putki', 'työtehtävä']);
        })(),
        (async () => {
          await this.getSalaryData();
        })(),
        (async () => {
          await this.reloadEntries();
        })(),
      ]);

      this.loading = false;
      this.subtleLoading = false;
    },

    async getAwayTypes() {
      const awayTypes = await itemsApi.getItemsByTypes(['poissaolo']);
      this.freeTimes = awayTypes.map(t => t.name);
    },
    async reloadEntries() {
      this.loadingEntries = true;
      let filterDates = [...this.filterDates];
      filterDates = filterDates.map((d, i) => {
        if (i === 0) {
          return dayjs(d).startOf('day').toISOString();
        } else {
          return dayjs(d).endOf('day').toISOString();
        }
      });
      const filterType = this.filterType === 'COMBINED' || this.filterType === 'STATS' ? ['COMBINED'] : [this.filterType];
      this.allEntries = (
        await timeentryApi.getTimeEntriesPaged({
          timeEntryTypes: filterType,
          dates: filterDates,
        })
      ).content;
      this.selectedItems = [];
      if (this.$refs.combinedSalaryDataTable) {
        this.$refs.combinedSalaryDataTable.resetSelectedItems();
      }
      this.loadingEntries = false;
    },

    async massUpdateInvoicedState() {
      const allInvoicedState = this.sameInvoicedState;
      const entryIds = [];
      this.filteredEntries.forEach(entry => entryIds.push(entry.id));
      await this.$store.dispatch('massUpdateInvoicedStates', {
        invoiced: allInvoicedState === true ? false : true,
        entryIds,
      });
    },
    getEntryDuration(entry, appendix = '') {
      if (!entry[`entryStart${appendix}`]) return 0;
      if (!entry[`entryEnd${appendix}`]) return this.$t('reports.unfinished');

      let diff;
      let start = entry[`entryStart${appendix}`];
      let end = entry[`entryEnd${appendix}`];

      if (entry.containsLunch) {
        end = dayjs(end).subtract(0.5, 'hours');
      }

      if (end.isBefore(start)) {
        diff = start.diff(end);
        return '-' + dayjs.duration(diff).asHours().toFixed(1);
      } else {
        diff = end.diff(start);
        return dayjs.duration(diff).asHours().toFixed(1);
      }
    },
    selectThisWeek() {
      this.filterDates = [dayjs().startOf('week').toISOString(), dayjs().endOf('week').toISOString()];
    },
    selectLastWeek() {
      this.filterDates = [
        dayjs().subtract(1, 'weeks').startOf('week').toISOString(),
        dayjs().subtract(1, 'weeks').endOf('week').toISOString(),
      ];
    },
    selectThisMonth() {
      this.filterDates = [dayjs().startOf('month').toISOString(), dayjs().endOf('month').toISOString()];
    },
    selectLastMonth() {
      this.filterDates = [
        dayjs().subtract(1, 'months').startOf('month').toISOString(),
        dayjs().subtract(1, 'months').endOf('month').toISOString(),
      ];
    },
    selectThisYear() {
      this.filterDates = [dayjs().startOf('year').toISOString(), dayjs().endOf('year').toISOString()];
    },
    selectLastYear() {
      this.filterDates = [
        dayjs().subtract(1, 'years').startOf('year').toISOString(),
        dayjs().subtract(1, 'years').endOf('year').toISOString(),
      ];
    },
    async fetchFilters() {
      const storedFilters = await this.$store.getters.getReportViewFilters;
      for (const key in storedFilters) {
        this[key] = storedFilters[key];
      }
      if (!storedFilters.filterDates.length) {
        this.filterDates = [dayjs().startOf('month').toISOString(), dayjs().endOf('day').toISOString()];
      }
      if (typeof storedFilters.filterType === 'undefined') {
        this.filterType = 'COMBINED';
      }
    },
  },
  computed: {
    COMBINED() {
      return this.filterType === 'COMBINED';
    },
    STATS() {
      return this.filterType === 'STATS';
    },
    TRAVELINVOICE() {
      return this.filterType === 'TRAVELINVOICE';
    },
    filterDatesText() {
      const filterDates = [];
      this.filterDates.forEach(date => {
        filterDates.push(this.$formatDateNoTime(dayjs(date)));
      });

      return filterDates.join(' - ');
    },
    entries() {
      const entries = [...(this.allEntries || [])];
      entries.map(e => {
        const userIndex = this.users.findIndex(u => u.id === e.userId);
        if (userIndex > -1) {
          e.user = this.users[userIndex].lastName
            ? `${this.users[userIndex].lastName} ${this.users[userIndex].firstName}`
            : this.users[userIndex].email;
        } else {
          e.user = this.$t('reports.could_not_find_user');
        }

        if (e.timeEntryType !== 'M0') {
          e.duration = this.getEntryDuration(e);
        }
        if (e.timeEntryType === 'M1' || e.timeEntryType === 'M2') e.durationReturn = this.getEntryDuration(e, 'Return');

        if (e.comments && e.comments.length > 0 && Array.isArray(e.comments)) {
          e._notifyComments = e.comments.some(comment => comment.notify && comment.user && comment.user.userId !== this.$userInfo.id);
        }

        /*e.equipmentString = '';
        e.equipments.forEach(eq => {
          e.equipmentString += eq.description + ' ';
        });*/
      });
      return entries;
    },
    invoiced() {
      return [
        { val: true, descr: this.$t('reports.invoiced') },
        { val: false, descr: this.$t('reports.not_invoiced') },
      ];
    },
    sameInvoicedState() {
      let t = 0;
      let f = 0;
      if (this.filteredEntries) {
        this.filteredEntries.forEach(entry => {
          if (entry.invoiced === true) t += 1;
          else f += 1;
        });
      }
      if (t === 0) return false;
      else if (f === 0) return true;
      else return undefined;
    },
    filteredEntries() {
      let filteredEntries = this.entries;

      const filterDates = [...this.filterDates];
      filterDates.sort();
      /*if (filterDates.length > 1) {
        filteredEntries = filteredEntries.filter(entry => {
          const entryStart = entry.entryStart.clone();
          const startIsInTimeRange = entryStart.isBetween(dayjs(filterDates[0]).startOf('day'), dayjs(filterDates[1]).endOf('day'));
          let endIsInTimeRange = true;
          if (entry.entryEnd) {
            const entryEnd = entry.entryEnd.clone();
            endIsInTimeRange = entryEnd.isBetween(dayjs(filterDates[0]).startOf('day'), dayjs(filterDates[1]).endOf('day'));
          }
          return startIsInTimeRange && endIsInTimeRange;
        });
      } else if (filterDates.length === 1) {
        filteredEntries = filteredEntries.filter(entry => {
          const entryStart = entry.entryStart.clone();
          const startIsSameDay = dayjs(entryStart).startOf('day').isSame(dayjs(filterDates[0]).startOf('day'));
          return startIsSameDay;
        });
      }*/

      if (
        this.filterUser.length === 0 &&
        this.filterSite.length === 0 &&
        this.filterEquipment.length === 0 &&
        this.filterInvoiced == null
      ) {
        return filteredEntries;
      }

      return filteredEntries.filter(entry => {
        let found = [];

        if (this.filterSite.length > 0) {
          const siteIndex = this.filterSite.findIndex(site => parseInt(entry?.site?.id, 10) === parseInt(site, 10));

          if (siteIndex > -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        if (this.filterUser.length > 0) {
          const userIndex = this.filterUser.findIndex(user => entry.userId === user);
          if (userIndex > -1) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        if (this.filterEquipment.length > 0) {
          let equipmentFound = false;
          entry.equipments.forEach(entryEquipment => {
            const equipmentIndex = this.filterEquipment.findIndex(equipment => entryEquipment.id === equipment);
            if (equipmentIndex > -1) {
              equipmentFound = true;
            }
          });
          found.push(equipmentFound);
        }

        if (this.filterInvoiced != null) {
          if (entry.invoiced === this.filterInvoiced) {
            found.push(true);
          } else {
            found.push(false);
          }
        }

        return found.indexOf(false) === -1;
      });
    },
    richerUsers() {
      const users = this.users;
      let filteredUsers = users;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return orderBy(
        filteredUsers.map(user => {
          const userCopy = Object.assign({}, user);
          userCopy.combinedInfo = `${userCopy.lastName} ${userCopy.firstName} ${userCopy.email}`;
          return userCopy;
        }),
        ['combinedInfo'],
        ['asc']
      );
    },
    filteredSites() {
      return this.sites.filter(s => s.status !== 'BILLED' && s.status !== 'DELETED');
    },
    selectedItemsToApprove() {
      return this.selectedItems.filter(entry => !entry.approved);
    },
  },
  watch: {
    async filterType(val) {
      this.$store.dispatch('setStoreFilters', {
        filterType: val,
      });
      if (val === 'COMBINED' || val === 'STATS') {
        this.filterSite = [];
        if (val === 'STATS') this.filterUser = [];
        await this.getSalaryData();
      }
      await this.reloadEntries();
    },
    async filterDates(val) {
      this.$store.dispatch('setStoreFilters', {
        filterDates: val,
      });
      if (this.COMBINED) {
        await this.getSalaryData();
      }
      if (this.STATS) {
        await this.getSalaryData();
      }

      await this.reloadEntries();
    },
    filterSite(val) {
      this.$store.dispatch('setStoreFilters', {
        filterSite: val,
      });
    },
    filterEquipment(val) {
      this.$store.dispatch('setStoreFilters', {
        filterEquipment: val,
      });
    },
    filterUser(val) {
      this.$store.dispatch('setStoreFilters', {
        filterUser: val,
      });
    },
    filterInvoiced(val) {
      this.$store.dispatch('setStoreFilters', {
        filterInvoiced: val,
      });
    },
  },
  async mounted() {
    await this.fetchFilters();
    this.createPayPeriods();
    this.reloadAll();
  },
};
</script>

<style lang="scss">
#entries-table {
  tr.bottom-border td {
    border-bottom: 1px solid var(--v-default-base);
  }

  .hidden-xlsx-only {
    display: none;
  }
}
</style>

<style scoped></style>
