<template>
  <hb-basic-page
    ref="hbBasicPage"
    :extension-height="$isTenantAdmin && adminMode ? 120 : 60"
    :fill-height="loading"
    :loading="loading"
    :title="adminMode ? $t('timer.admin_title') : $t('timer.title')"
  >
    <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:app-bar-extension>
      <v-row class="">
        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-if="adminMode"
                id="worker-select"
                :items="richerUsers"
                :label="$t('timer.user')"
                :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
                :placeholder="$t('timer.choose_user')"
                :value="currentUser"
                class="title"
                color="primary"
                dense
                item-text="combinedInfo"
                item-value="id"
                outlined
                return-object
                @change="onChangeCurrentUser"
              />
            </v-col>
          </v-row>
          <v-row class="mt-n10">
            <v-col cols="12">
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
                class="mb-3 ml-n1 mr-3"
                color="primary"
                :disabled="loading"
                dark
                @click="newTimeEntry"
              >
                <v-icon class="no-gradient" left>mdi mdi-clock</v-icon>
                <span>{{ $t('timer.create_new_entry') }}</span>
              </v-btn>
              <v-btn
                v-if="$vuetify.breakpoint.smAndUp"
                :class="{ 'ma-0': $vuetify.breakpoint.smAndUp }"
                class="mb-3 ml-n1 mr-3"
                color="primary"
                :disabled="loading"
                dark
                @click="newTravelInvoice"
              >
                <v-icon class="no-gradient" left>mdi mdi-wallet-travel</v-icon>
                <span>{{ $t('timer.create_new_travel_invoice') }}</span>
              </v-btn>
              <v-btn v-if="$vuetify.breakpoint.smAndUp" :disabled="loading" class="mb-3" color="primary" dark @click="newAwayEntry">
                <v-icon :left="$vuetify.breakpoint.smAndUp" class="no-gradient">mdi mdi-run</v-icon>
                <span>{{ $t('timer.new_away_entry') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>

    <hb-loading-indicator v-if="loading" align-middle />
    <v-col class="gradient-icons" cols="12">
      <v-row
        v-if="adminMode && $vuetify.breakpoint.smAndDown"
        :style="{ width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto' }"
        style="position: fixed; z-index: 7"
      >
        <v-col cols="12">
          <v-autocomplete
            v-if="adminMode"
            id="worker-select"
            :items="richerUsers"
            :label="$t('timer.user')"
            :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
            :placeholder="$t('timer.choose_user')"
            :value="currentUser"
            class="title"
            color="primary"
            dense
            item-text="combinedInfo"
            item-value="id"
            return-object
            solo
            @change="onChangeCurrentUser"
          />
        </v-col>
      </v-row>
      <v-row
        :style="{ width: $vuetify.breakpoint.xsOnly ? '100%' : 'auto', top: adminMode && $vuetify.breakpoint.smAndDown ? '95px' : null }"
        style="position: fixed; z-index: 7"
      >
        <v-col>
          <v-menu ref="dateRangePicker" v-model="dateRangePicker" :close-on-content-click="false" offset-y transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filterDatesText"
                :style="{ maxWidth: $vuetify.breakpoint.smAndUp ? '300px' : '100%' }"
                append-icon="filter_list"
                background-color=""
                hide-details
                readonly
                single-line
                solo
                v-on="on"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filterDatesText"
                    append-icon="filter_list"
                    background-color=""
                    hide-details
                    readonly
                    single-line
                    solo
                    v-on="on"
                  >
                    <template slot="prepend-inner">
                      <v-icon class="mr-2">event</v-icon>
                    </template>
                  </v-text-field>
                </template>
              </v-text-field>
            </template>
            <v-card>
              <v-card-text v-if="$vuetify.breakpoint.xsOnly" class="dayRanges-scrollable-content">
                <v-row no-gutters>
                  <v-col v-for="dayRange in dayRanges" :key="dayRange.label" cols="12">
                    <v-btn block small text @click="filterDates = dayRange.dates">{{ dayRange.label }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider v-if="$vuetify.breakpoint.xsOnly"></v-divider>
              <v-card-text>
                <v-row>
                  <div v-if="$vuetify.breakpoint.smAndUp" class="mr-1">
                    <v-btn
                      v-for="dayRange in dayRanges"
                      :key="dayRange.label"
                      :block="$vuetify.breakpoint.smAndUp"
                      small
                      text
                      @click="filterDates = dayRange.dates"
                      >{{ dayRange.label }}
                    </v-btn>
                  </div>
                  <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical></v-divider>
                  <v-date-picker
                    v-model="filterDates"
                    :full-width="$vuetify.breakpoint.xsOnly"
                    class="elevation-0"
                    color=""
                    first-day-of-week="1"
                    locale="fi-fi"
                    no-title
                    :locale-first-day-of-year="4"
                    range
                    scrollable
                    show-week
                  ></v-date-picker>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small text @click="dateRangePicker = false">{{ $t('close') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
      <v-row
        v-if="entriesByDate.length > 0"
        :class="[$vuetify.breakpoint.smAndDown ? 'mb-15' : 'mb-0']"
        :style="{ marginTop: adminMode && $vuetify.breakpoint.smAndDown ? '100px' : '32px' }"
        no-gutters
      >
        <v-col cols="12">
          <v-list color="transparent" subheader two-line>
            <template v-for="(day, i) in entriesByDate">
              <v-subheader
                :key="(day.date && day.date.format('YYYY_MM_DD')) + i + '_header'"
                :class="i === 0 ? 'mt-3' : 'mt-4'"
                class="mt-5 accent--text font-weight-medium"
                style="height: 40px"
              >
                <v-row align="start" justify="space-between" no-gutters>
                  <v-col cols="9">
                    <span class="text-capitalize">{{ formatTime(day.date) }}</span
                    ><span class="text--darken-1">&nbsp;{{ $formatDateNoTime(day.date) }}</span>
                  </v-col>
                  <v-col class="text-right caption font-weight-medium" cols="3">
                    <duration-display :day="day" :entries="day.entries" :freeTimes="freeTimes" :salaryData="salaryData" dayDuration />
                  </v-col>
                </v-row>
              </v-subheader>

              <v-divider :key="day.date.format('YYYY_MM_DD') + '_divider'"></v-divider>

              <template v-for="(entry, index) in day.entries.filter(e => e.timeEntryType !== 'M0')">
                <v-list-item
                  :key="entry.id + '_listitem'"
                  :class="[entry.entryEnd ? 'grey lighten-4' : 'white']"
                  :light="!entry.entryEnd"
                  @click="openEntryInDialog(entry, day.date)"
                >
                  <v-list-item-content>
                    <v-list-item-title :class="siteTitleColor(entry)">{{ entryTitle(entry) }}</v-list-item-title>
                    <v-list-item-subtitle v-if="entry.site">
                      {{ entry.site && entry.site.project && entry.site.project.orderer ? entry.site.project.orderer.companyName : '' }},
                      {{ entry.site ? entry.site.name : '' }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>{{ entry.site ? entry.site.address : '' }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text v-if="entryTimeOverlap(entry.id)">
                    <template>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="warning" class="no-gradient">mdi-alert-circle-outline</v-icon>
                        </template>
                        <span>{{ $t('reports.clash_in_entries') }}</span>
                      </v-tooltip>
                    </template>
                  </v-list-item-action-text>
                  <v-list-item-action-text v-if="entry.workType && freeTimes.indexOf(entry.workType.name) === -1">
                    <div :class="!entry.entryEnd ? 'success--text' : ''" class="caption pl-2 text-right">
                      {{ [$formatTimeNoDate(entry.entryStart), $formatTimeNoDate(entry.entryEnd)].filter(Boolean).join('-') }}<br />
                      <duration-display :entries="[entry]" :freeTimes="freeTimes" />
                    </div>
                  </v-list-item-action-text>
                </v-list-item>

                <timer-entry-divider
                  :key="entry.id + '_divider'"
                  :day-entries="day.entries.filter(e => e.timeEntryType !== 'M0')"
                  :entry="entry"
                  :entry-time-overlap="entryTimeOverlap"
                  :index="index"
                />
              </template>

              <div :key="day.date.format('YYYY_MM_DD') + '_controls'" class="py-2">
                <v-btn block class="mb-2" color="primary" outlined small text @click="newTimeEntry(day.date)"
                  >{{ $t('timer.add_time_entry_for_this_date') }}
                </v-btn>
                <v-btn block color="primary" outlined small text @click="newTravelInvoice(day.date)"
                  >{{ $t('timer.add_travel_entry_for_this_date') }}
                </v-btn>
              </div>

              <template v-if="day.entries.length === 0">
                <div :key="day.date.format('YYYY_MM_DD') + '_no_entries'" class="text-center caption grey--text mt-2">
                  {{ $t('timer.no_entries_for_this_date') }}
                </div>
              </template>

              <TravelInvoiceOptions
                v-if="day.entries.length > 0"
                :key="day.date.format('YYYY_MM_DD') + '_travelinvoice_options'"
                :admin-mode="adminMode"
                :day="day"
                :free-times="freeTimes"
                :propsUser="currentUser"
                @entry-updated="reloadTimeEntries"
              />
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-speed-dial
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="fab"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
      bottom
      fixed
      right
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="primary" dark fab>
          <v-icon v-if="fab" class="no-gradient"> mdi mdi-close</v-icon>
          <v-icon v-else class="no-gradient"> mdi mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-btn color="primary darken-1" dark fab @click="newTravelInvoice">
        <v-icon class="no-gradient">mdi mdi-wallet-travel</v-icon>
      </v-btn>
      <v-btn color="primary darken-1" dark fab @click="newTimeEntry">
        <v-icon class="no-gradient">mdi mdi-clock</v-icon>
      </v-btn>
      <v-btn v-if="!$isOnlyTenantBasic" color="primary darken-1" dark fab @click="newDaybook">
        <v-icon class="no-gradient">mdi mdi-book-plus-outline</v-icon>
      </v-btn>
      <v-btn color="primary darken-1" dark fab @click="newAwayEntry">
        <v-icon class="no-gradient">mdi mdi-run</v-icon>
      </v-btn>
    </v-speed-dial>
    <add-away-entry-dialog
      ref="addAwayEntryDialog"
      :admin-mode="adminMode"
      :props-user="currentUser"
      :users="users"
      @away-entry-changed="awayEntryChange"
    />
    <entry-dialog
      ref="entryDialog"
      :admin-mode="adminMode"
      :equipments-array="equipments"
      :freeTimes="freeTimes"
      :items-array="items"
      :props-user="currentUser"
      :sites-array="sites"
      :travel-invoice-calculation="worker.travelInvoiceCalculation"
      :users="users"
      @dismiss="handleEntryDialogDismiss"
      @comment-created="updateWorkTaskList"
    />
    <select-site-dialog ref="selectSiteDialog" />
  </hb-basic-page>
</template>

<script>
import EntryDialog from '../../components/Timer/EntryDialog.vue';
import DurationDisplay from '../../components/Timer/DurationDisplay.vue';
import EntryService from '../../service/entry.service';
import sitesApi from '@/api/site';
import itemsApi from '@/api/item';
import tenantApi from '@/api/tenant';
import equipmentsApi from '@/api/equipment';
import timeEntriesApi from '@/api/timeentries';
import dayjs from 'dayjs';
import SelectSiteDialog from '@/components/SiteDaybook/selectSiteDialog';
import AddAwayEntryDialog from '@/components/Timer/AddAwayEntryDialog';
import TravelInvoiceOptions from '../../components/Timer/TravelInvoiceOptions';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import TimerEntryDivider from '@/views/tenant/TimerEntryDivider.vue';

export default {
  name: 'TimerView',
  props: ['id', 'adminMode'],
  components: {
    TimerEntryDivider,
    TravelInvoiceOptions,
    AddAwayEntryDialog,
    SelectSiteDialog,
    EntryDialog: EntryDialog,
    DurationDisplay: DurationDisplay,
  },
  data() {
    return {
      salaryData: [],
      freeTimes: [],
      direction: 'top',
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      right: true,
      bottom: true,
      transition: 'slide-y-reverse-transition',
      loading: true,
      subtleLoading: false,
      searchPhrase: '',
      offsetTop: 0,
      currentTime: false,
      activeTab: 0,
      sites: [],
      equipments: [],
      users: [],
      items: [],
      entries: [],
      worker: {},
      dayRanges: [],
      filterDates: [dayjs().startOf('month').toISOString(), dayjs().endOf('day').toISOString()],
      dateRangePicker: false,
      currentUser: null,
    };
  },
  methods: {
    async onChangeCurrentUser(user) {
      this.currentUser = user;

      await this.getMe();
      await this.reloadAll();
    },
    siteTitleColor(entry) {
      if (entry?.site) {
        return entry.site.name;
      }
      switch (entry?.workType?.name) {
        case 'Loma':
        case 'Perhevapaa':
        case 'Arkipyhä':
          return 'success--text';
        case 'Sairausloma':
          return 'error--text';
        default:
          return 'warning--text';
      }
    },
    entryTimeOverlap(entryId) {
      if (this.clashedEntries.some(ce => ce.id === entryId)) {
        return true;
      }
      return false;
    },
    entryTitle(entry) {
      if (entry.timeEntryType === 'M1' || entry.timeEntryType === 'M2') {
        return `${this.$t('timer.travel_time')} ${entry.timeEntryType}`;
      } else {
        return entry?.workType?.name;
      }
    },
    newAwayEntry() {
      if (this.$refs.addAwayEntryDialog) {
        this.$refs.addAwayEntryDialog.open();
      }
    },
    newDaybook() {
      if (this.$refs.selectSiteDialog) {
        this.$refs.selectSiteDialog.open();
      }
    },
    handleEntryDialogDismiss() {
      if (this.id) {
        this.$router.replace({ name: 'tenant_timer' });
      }
      this.reloadTimeEntries();
    },
    updateWorkTaskList() {
      this.$store.dispatch('reloadWorkTasks', { tenantId: this.$tenant });
    },
    async openWorkTaskTarget(entryId) {
      const entry = await timeEntriesApi.getTimeEntry(entryId);
      if (entry.timeEntryType === 'M1' || entry.timeEntryType === 'M2') {
        this.activeTab = 1;
      } else if (entry.timeEntryType === 'TIMEENTRY') {
        this.activeTab = 0;
      }
      await this.$refs.entryDialog.openEntry(entry, false, false, true);
    },
    getUserNameById(userId) {
      const user = this.users.filter(u => u.id === userId);
      const userFullName = user[0].firstName + ' ' + user[0].lastName;
      return userFullName;
    },
    async getUsers() {
      const users = await tenantApi.loadTenantUsers();
      this.users = users;
    },
    newTravelInvoice(date) {
      this.$refs.entryDialog.openNew(date, 'travelinvoice');
    },
    newTimeEntry(date) {
      const ebd = this.entriesByDate.filter(dateObj => dateObj.entries.length > 0);
      const modifiedEBD = ebd.map(obj => ({
        date: obj.date.format('YYYY-MM-DD'),
        entries: obj.entries,
      }));
      this.$refs.entryDialog.openNew(date, 'timeentry', this.currentUser.id, modifiedEBD);
    },
    canModifyEntry(entry) {
      return EntryService.canModifyEntry(entry, this.$userInfo);
    },
    openEntryInDialog(entry, date = null) {
      if (entry.timeEntryType === 'AWAYENTRY' || this.freeTimes.includes(entry.workType?.name)) {
        this.$refs.addAwayEntryDialog.openEntry(entry);
      } else {
        const ebd = this.entriesByDate.filter(dateObj => dateObj.entries.length > 0);
        this.$refs.entryDialog.openEntry(entry, false, false, ebd, date);
      }
    },
    formatTime(time) {
      // @todo locale for multiple languages in case project ever goes multilingual
      return dayjs(time).locale('FI-fi').calendar(null, {
        sameDay: '[Tänään]',
        nextDay: '[Huomenna]',
        nextWeek: 'dddd[na]',
        lastDay: '[Eilen]',
        lastWeek: 'dddd',
        sameElse: 'dddd',
      });
    },
    async getMe() {
      try {
        this.worker = await this.$userApi.getMeWorker(this.currentUser.id);
      } catch (e) {
        this.$showErrorNotification(this.$t('error_missing_worker'));
      }
    },
    awayEntryChange(status) {
      this.reloadTimeEntries();
      if (status) {
        switch (status) {
          case 'created':
            return this.$showSuccessNotification(this.$t('timer.away_entry_created'));
          case 'updated':
            return this.$showSuccessNotification(this.$t('timer.away_entry_updated'));
          case 'deleted':
            return this.$showSuccessNotification(this.$t('timer.entry_deleted'));
        }
      }
    },
    async reloadTimeEntries() {
      const filterDates = this.filterDates.map((f, idx) => {
        if (f) {
          if (idx === 0) {
            return dayjs(f).startOf('day').toISOString();
          } else {
            return dayjs(f).endOf('day').toISOString();
          }
        }
        return null;
      });

      this.salaryData = await timeEntriesApi.getSalaryData({ dates: filterDates, userIds: [this.currentUser.id] });
      if (this.currentUser.id === this.$userInfo.id) {
        this.entries = (
          await timeEntriesApi.getOwnTimeEntries({
            dates: filterDates,
            userIds: [this.currentUser.id],
          })
        ).content;
      } else {
        this.entries = (
          await timeEntriesApi.getTimeEntriesPaged({
            dates: filterDates,
            userIds: [this.currentUser.id],
          })
        ).content;
      }
    },
    async reloadAll() {
      this.loading = true;
      this.subtleLoading = false;

      await this.reloadTimeEntries();

      Promise.all([
        (async () => {
          this.sites = await sitesApi.getSites();
        })(),
        (async () => {
          this.items = await itemsApi.getItemsByTypes(['putki', 'työtehtävä']);
        })(),
        (async () => {
          this.equipments = await equipmentsApi.getAllEquipments();
        })(),
      ]);
      await this.getUsers();
      this.loading = false;
      this.subtleLoading = false;
    },
    createPayPeriods() {
      const r = this.$createPayPeriods();
      this.filterDates = r.filterDates;
      this.dayRanges = r.dayRanges;
    },
    async getAwayTypes() {
      const awayTypes = await itemsApi.getItemsByTypes(['poissaolo']);
      this.freeTimes = awayTypes.map(t => t.name);
    },
  },
  watch: {
    async filterDates(newValue) {
      if (newValue && newValue.length === 2) await this.reloadTimeEntries();
    },
    id(to, from) {
      if (!!to && to !== from) {
        this.openWorkTaskTarget(to);
      }
    },
  },
  computed: {
    richerUsers() {
      const users = this.users;
      let filteredUsers = users;
      if (this.$userInfo.email && !this.$userInfo.email.includes('@hubble.fi')) {
        filteredUsers = filteredUsers.filter(user => user.email && !user.email.includes('@hubble.fi'));
      }
      return orderBy(
        filteredUsers.map(user => {
          const userCopy = Object.assign({}, user);
          userCopy.combinedInfo = `${userCopy.lastName} ${userCopy.firstName}`;
          return userCopy;
        }),
        ['combinedInfo'],
        ['asc']
      );
    },
    activeEntry() {
      const entryWithoutEnd = this.filteredEntries.findIndex(entry => !entry.entryEnd);

      if (entryWithoutEnd > -1) {
        return this.filteredEntries[entryWithoutEnd];
      }
      return false;
    },
    clashedEntries() {
      let clashes = [];
      this.entriesByDate.forEach(date => {
        if (date.entries && date.entries.length) {
          date.entries.forEach(thisEntry => {
            const returnObject = {
              id: thisEntry.id,
              end: false,
              start: false,
            };
            date.entries
              .filter(otherEntry => otherEntry.id !== thisEntry.id)
              .forEach(otherEntry => {
                if (returnObject.end === false) {
                  returnObject.end = moment(thisEntry.entryEnd.format()).isBetween(
                    otherEntry.entryStart.format(),
                    otherEntry.entryEnd.format()
                  );
                }
                if (returnObject.start === false) {
                  returnObject.start = moment(thisEntry.entryStart.format()).isBetween(
                    otherEntry.entryStart.format(),
                    otherEntry.entryEnd.format()
                  );
                }
                if (returnObject.end || returnObject.start) {
                  const existingEntry = clashes.findIndex(c => c.id === returnObject.id);
                  if (existingEntry === -1) {
                    clashes.push(returnObject);
                  } else {
                    clashes[existingEntry].start = existingEntry.start || returnObject.start;
                    clashes[existingEntry].end = existingEntry.end || returnObject.end;
                  }
                }
              });
          });
        }
      });
      return clashes;
    },
    filteredEntries() {
      return this.entries;
    },
    filterDatesText() {
      const filterDates = [];
      this.filterDates.forEach(date => {
        filterDates.push(this.$formatDateNoTime(dayjs(date)));
      });
      return filterDates.join(' - ');
    },
    entriesByDate() {
      const entriesByDate = [];

      let start = dayjs(this.filterDates[0]).startOf('day');
      let end = dayjs(this.filterDates[1]).endOf('day');
      const today = dayjs().endOf('day');

      if (today.isBefore(end)) {
        end = today;
      }

      while (start.isBefore(end)) {
        entriesByDate.push({
          date: start,
          entries: [],
        });
        start = start.add(1, 'days');
      }
      this.filteredEntries.forEach(entry => {
        const ebdIndex = entriesByDate.findIndex(ebd => dayjs(ebd.date).startOf('day').isSame(dayjs(entry.entryStart).startOf('day')));
        if (ebdIndex > -1) {
          entriesByDate[ebdIndex].entries.push(entry);
        } else {
          entriesByDate.push({
            date: dayjs(entry.entryStart),
            entries: [entry],
          });
        }
      });
      entriesByDate.map(ebd => {
        ebd.entries.sort((a, b) => {
          if (!a.entryEnd && !b.entryEnd) return 0;
          if (!a.entryEnd) return 1;
          if (!b.entryEnd) return -1;
          return a.entryStart - b.entryStart;
        });
      });
      entriesByDate.sort((a, b) => {
        return b.date - a.date;
      });
      return entriesByDate;
    },
    lastTwoEntries() {
      const entries = [...this.filteredEntries];

      entries.sort((a, b) => {
        if (!a.entryEnd && !b.entryEnd) return 0;
        if (!a.entryEnd) return -1;
        if (!b.entryEnd) return 1;
        return b.entryStart - a.entryStart;
      });
      const latestEntriesBySideId = [];
      entries.forEach(entry => {
        if (latestEntriesBySideId.findIndex(ge => ge.siteId === entry.siteId) === -1) {
          latestEntriesBySideId.push(entry);
        }
      });
      return latestEntriesBySideId.slice(0, 2);
    },
  },
  async mounted() {
    await this.getAwayTypes();

    await this.$vuetify.goTo(0, {
      duration: 0,
      offset: 0,
    });

    if (this.$isLoggedIn) {
      this.currentUser = this.$userInfo;
      this.createPayPeriods();
      await this.getMe();
      await this.reloadAll();
    }
    if (this.id) {
      this.openWorkTaskTarget(this.id);
    }
  },
};
</script>

<style langs="scss" scoped>
.dayRanges-scrollable-content {
  max-height: 100px;
  overflow: scroll;
}
.border-bottom-red {
  border-bottom: 1px solid red;
}
</style>
