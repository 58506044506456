<template>
  <v-row>
    <v-col cols="12">
      <v-row v-if="givenEntryClashesWithExisting && givenEntryClashesWithExisting.length" class="mb-4 mt-n6" no-gutters>
        <v-alert icon="mdi-information-outline no-gradient " outlined type="warning" width="100%">
          {{
            $tc('timer.clash_in_entries', this.givenEntryClashesWithExisting.length === 1 ? 1 : 0) +
            ': ' +
            givenEntryClashesWithExisting.filter(Boolean).join(', ')
          }}
        </v-alert>
      </v-row>
      <v-row v-else-if="givenEntryWillCreateGap" class="mb-4 mt-n6" no-gutters>
        <v-alert icon="mdi-information-outline" outlined type="info" width="100%">
          {{ $t('timer.gap_in_entries') + otherEntryRangesToString }}
        </v-alert>
      </v-row>
      <v-row no-gutters>
        <v-col :cols="showDate ? 6 : 12">
          <v-dialog ref="startTimeDialog" v-model="startTimeModal" :disabled="currentEntry.disabled" persistent width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                :clearable="clearable"
                :disabled="currentEntry.disabled"
                :label="travelInvoice ? $t('timer.travel_invoice_start_time') : $t('timer.start_time')"
                :value="currentEntry[`entryStartTime${appendix}`]"
                class="title"
                dense
                readonly
                @change="entryStartTimeClear"
                v-on="on"
              >
                <template slot="prepend">
                  <div style="width: 25px; text-align: center">
                    <v-icon color="grey" size="18">fas fa-clock</v-icon>
                  </div>
                </template>
              </v-text-field>
            </template>
            <v-time-picker
              v-if="startTimeModal"
              v-model="currentEntry[`entryStartTime${appendix}`]"
              :allowed-minutes="[0, 30]"
              color="primary"
              format="24hr"
              full-width
              light
            >
              <v-btn text @click="startTimeModal = false">{{ $t('cancel') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" @click="startTimeModal = false">{{ $t('ok') }}</v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="showDate" cols="6">
          <v-dialog ref="startDateDialog" v-model="startDateModal" :disabled="currentEntry.disabled" light persistent width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                :clearable="clearable"
                :disabled="currentEntry.disabled"
                :value="$formatDateNoTime(currentEntry[`entryStartDate${appendix}`])"
                class="ml-2 title"
                dense
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentEntry[`entryStartDate${appendix}`]"
              color="primary"
              first-day-of-week="1"
              :locale-first-day-of-year="4"
              light
              locale="fi-fi"
              scrollable
            >
              <v-btn text @click="startDateModal = false">{{ $t('cancel') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" @click="startDateModal = false">{{ $t('ok') }}</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
    <v-col :class="showDate ? 'pt-0 pb-0' : ''" cols="12">
      <v-row no-gutters>
        <v-col :cols="showDate ? 6 : 12">
          <v-dialog ref="endTimeDialog" v-model="endTimeModal" :disabled="currentEntry.disabled" persistent width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                :clearable="clearable"
                :disabled="currentEntry.disabled"
                :label="travelInvoice ? $t('timer.travel_invoice_end_time') : $t('timer.end_time')"
                :value="currentEntry[`entryEndTime${appendix}`]"
                class="title"
                dense
                placeholder="--:--"
                readonly
                @change="entryEndTimeClear"
                v-on="on"
              >
                <template slot="prepend">
                  <div style="width: 25px; padding-top: 4px; text-align: center"></div>
                </template>
              </v-text-field>
            </template>
            <v-time-picker
              v-if="endTimeModal"
              v-model="currentEntry[`entryEndTime${appendix}`]"
              :allowed-minutes="[0, 30]"
              color="primary"
              format="24hr"
              full-width
              light
            >
              <v-btn text @click="endTimeModal = false">{{ $t('cancel') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" @click="endTimeModal = false">{{ $t('ok') }}</v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col v-if="showDate" cols="6">
          <v-dialog v-if="showDate" ref="endDateDialog" v-model="endDateModal" :disabled="currentEntry.disabled" persistent width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                :clearable="clearable"
                :disabled="currentEntry.disabled"
                :value="$formatDateNoTime(currentEntry[`entryEndDate${appendix}`])"
                class="ml-2 title"
                dense
                placeholder="--.--.----"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentEntry[`entryEndDate${appendix}`]"
              color="primary"
              first-day-of-week="1"
              light
              locale="fi-fi"
              scrollable
              :locale-first-day-of-year="4"
            >
              <v-btn text @click="endDateModal = false">{{ $t('cancel') }}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" elevation="0" @click="endDateModal = false">{{ $t('ok') }}</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="pt-0 pb-0" cols="12">
      <template v-if="dialog">
        <duration-dialog
          ref="durationDialog"
          :containsLunch="containsLunch"
          :default-work-hours="defaultWorkHours"
          :disabled="currentEntry.disabled"
          :end-date="currentEntry[`entryEndDate${appendix}`]"
          :end-time="currentEntry[`entryEndTime${appendix}`]"
          :start-date="currentEntry[`entryStartDate${appendix}`]"
          :start-time="currentEntry[`entryStartTime${appendix}`]"
          @duration="emitDuration"
          @on-save="value => handleDurationDialogSave(value, appendix)"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import DurationDialog from '@/components/Timer/DurationDialog';
import dayjs from 'dayjs';

export default {
  name: 'EntryTimes',
  components: { DurationDialog },
  props: {
    defaultWorkHours: Number,
    currentEntry: Object,
    appendix: {
      type: String,
      default: '',
    },
    containsLunch: {
      type: Boolean,
    },
    travelInvoice: Boolean,
    clearable: Boolean,
    handleDurationDialogSave: Function,
    dialog: Boolean,
    showDate: {
      type: Boolean,
      default: true,
    },
    existingEntryRanges: {
      type: [Boolean, Array],
    },
  },
  data() {
    return {
      startTimeModal: false,
      startDateModal: false,
      endTimeModal: false,
      endDateModal: false,
      localCurrentEntry: {},
      localEERanges: {},
    };
  },
  watch: {
    currentEntry: {
      immediate: true,
      handler(val) {
        if (val) {
          this.localCurrentEntry = Object.assign({}, val);
        }
      },
      deep: true,
    },
    existingEntryRanges: {
      immediate: true,
      handler(val) {
        if (val) {
          this.localEERanges = val.map(a => {
            return { ...a };
          });
        }
      },
    },
  },
  computed: {
    entryStartDateTime() {
      if (!this.localCurrentEntry.entryStartTime || !this.localCurrentEntry.entryStartDate) {
        return false;
      } else {
        const startDate = dayjs(this.localCurrentEntry[`entryStartDate${this.appendix}`], 'YYYY-MM-DD');
        const startTime = dayjs(this.localCurrentEntry[`entryStartTime${this.appendix}`], 'HH:mm');
        return startDate.set({
          hour: startTime.hour(),
          minute: startTime.minute(),
        });
      }
    },
    entryEndDateTime() {
      if (!this.localCurrentEntry.entryEndTime || !this.localCurrentEntry.entryEndDate) {
        return false;
      } else {
        const endDate = dayjs(this.localCurrentEntry[`entryEndDate${this.appendix}`], 'YYYY-MM-DD');
        const endTime = dayjs(this.localCurrentEntry[`entryEndTime${this.appendix}`], 'HH:mm');
        const endDateTime = endDate;
        return endDateTime.set({
          hour: endTime.hour(),
          minute: endTime.minute(),
        });
      }
    },
    givenEntryClashesWithExisting() {
      if (
        !this.localEERanges ||
        !this.localEERanges.length ||
        !this.localCurrentEntry.entryEndTime ||
        !this.localCurrentEntry.entryStartTime
      )
        return false;
      return this.localEERanges
        .map(range => {
          if (
            (this.localCurrentEntry && this.localCurrentEntry.id && this.localCurrentEntry.id !== range.id) ||
            typeof this.localCurrentEntry.id === undefined ||
            this.localCurrentEntry.id === null
          ) {
            if (this.timeRangeOverlap(this.entryStartDateTime, this.entryEndDateTime, range.start, range.end)) {
              return `${range.start.format('HH:mm')} - ${range.end.format('HH:mm')}`;
            }
          }
        })
        .filter(Boolean);
    },
    givenEntryWillCreateGap() {
      if (!this.localEERanges || !this.localEERanges.length || !this.entryStartDateTime || !this.entryEndDateTime) return false;
      return this.checkTimeGap();
    },
    otherEntryRangesToString() {
      if (this.localEERanges && this.localEERanges.length) {
        return this.localEERanges
          .map(r => {
            if (r.id !== this.localCurrentEntry.id) {
              return `${r.start.format('HH:mm')} - ${r.end.format('HH:mm')}`;
            }
          })
          .filter(Boolean)
          .join(', ');
      }
      return false;
    },
  },
  methods: {
    timeRangeOverlap(startTime1, endTime1, startTime2, endTime2) {
      return (
        (startTime1.isBefore(endTime2) && endTime1.isAfter(startTime2)) || (startTime2.isBefore(endTime1) && endTime2.isAfter(startTime1))
      );
    },
    checkTimeGap() {
      const timeRange1 = {
        start: this.entryStartDateTime,
        end: this.entryEndDateTime,
      };

      for (const timeRange2 of this.localEERanges) {
        if (this.localCurrentEntry.id === timeRange2.id) {
          // ei vertailla itseensä.
          continue;
        }
        const start2 = dayjs(timeRange2.start);
        const end2 = dayjs(timeRange2.end);
        if (timeRange1.end.isSame(start2) || timeRange1.start.isSame(end2)) {
          // liitoskohdan löytyessä palautetaan false
          return false;
        } else {
          continue;
        }
      }
      // jos ei löydy niin palautetaan true
      return true;
    },
    emitDuration(value) {
      this.$emit('duration', value);
    },
    entryStartTimeClear(value) {
      this.localCurrentEntry[`entryStartTime${this.appendix}`] = value;
    },
    entryEndTimeClear(value) {
      this.localCurrentEntry[`entryEndTime${this.appendix}`] = value;
    },
  },
};
</script>

<style scoped></style>
